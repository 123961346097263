'use strict';

import 'three';
import 'three/examples/js/renderers/Projector';
import 'three/examples/js/renderers/CanvasRenderer';

/**
 * В этом файле нельзя использовать jquery
 */
class CPoints {
    constructor(heigth = 600) {
        console.log('init particles');

        let windowHalfX = window.innerWidth / 2;
        let windowHalfY = window.innerHeight / 2;

        let width = window.innerWidth;

        //настройки точек
        let pColor = 0xdd8a90; //цвет
        let pSize = 0.2; //размер
        let SEPARATION = 100; //расстояние между
        let AMOUNTX = 50; //кол-во по горизонатли
        let AMOUNTY = 50; //кол-во по вертикали

        //настройки камеры
        let bgColor = 0xF4EDEF;
        let fov = 75; //Camera frustum vertical field of view
        let aspect = window.innerWidth / heigth; //Camera frustum aspect ratio (width / heigth)
        let near = 1; //Camera frustum near plane
        let far = 10000; //Camera frustum far plane
        let cPosition = 1000; //z-позиция камеры

        let container;
        let camera, scene, renderer;
        let particles, particle, count = 0;
        let mouseX = 0, mouseY = 0;


        init();
        animate();

        function init() {
            container = document.getElementById("particlies");

            scene = new THREE.Scene();

            camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
            camera.position.z = cPosition;

            renderer = new THREE.CanvasRenderer();
            renderer.setPixelRatio(window.devicePixelRatio);
            renderer.setSize(width, heigth);
            renderer.setClearColor(bgColor, 1);
            container.appendChild(renderer.domElement);

            particles = [];

            let material = new THREE.SpriteCanvasMaterial({
                color: pColor,
                program: function (context) {
                    context.beginPath();
                    context.arc(0, 0, pSize, 0, Math.PI * 2, true);
                    context.fill();
                }
            });

            let i = 0;
            for (let ix = 0; ix < AMOUNTX; ix++) {
                for (let iy = 0; iy < AMOUNTY; iy++) {
                    particle = particles[i++] = new THREE.Sprite(material);
                    particle.position.x = ix * SEPARATION - ((AMOUNTX * SEPARATION) / 2);
                    particle.position.z = iy * SEPARATION - ((AMOUNTY * SEPARATION) / 2);
                    scene.add(particle);
                }
            }

            document.addEventListener('mousemove', onDocumentMouseMove, false);
            //document.addEventListener('touchstart', onDocumentTouchStart, false);
            //document.addEventListener('touchmove', onDocumentTouchMove, false);
            window.addEventListener('resize', onWindowResize, false);
        }

        function onWindowResize() {
            windowHalfX = window.innerWidth / 2;
            windowHalfY = window.innerHeight / 2;
            camera.aspect = window.innerWidth / heigth;
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, heigth);
        }

        //
        function onDocumentMouseMove(event) {
            mouseX = event.clientX - windowHalfX;
            mouseY = event.clientY - windowHalfY;
        }

        function onDocumentTouchStart(event) {
            if (event.touches.length === 1) {
                event.preventDefault();
                mouseX = event.touches[0].pageX - windowHalfX;
                mouseY = event.touches[0].pageY - windowHalfY;
            }
        }

        function onDocumentTouchMove(event) {
            if (event.touches.length === 1) {
                event.preventDefault();
                mouseX = event.touches[0].pageX - windowHalfX;
                mouseY = event.touches[0].pageY - windowHalfY;
            }
        }

        //
        function animate() {
            requestAnimationFrame(animate);
            render();
        }

        function render() {
            camera.position.x += (mouseX - camera.position.x) * .05;
            camera.position.y += (-mouseY - camera.position.y) * .05;
            camera.lookAt(scene.position);

            let i = 0;
            for (let ix = 0; ix < AMOUNTX; ix++) {
                for (let iy = 0; iy < AMOUNTY; iy++) {
                    particle = particles[i++];
                    particle.position.y = (Math.sin((ix + count) * 0.3) * 50) +
                        (Math.sin((iy + count) * 0.5) * 50);
                    particle.scale.x = particle.scale.y = (Math.sin((ix + count) * 0.3) + 1) * 4 +
                        (Math.sin((iy + count) * 0.5) + 1) * 4;
                }
            }

            renderer.render(scene, camera);
            count += 0.1;
        }


    }
}

$(window).bind("load.particles", () => new CPoints());
